import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import axios from "axios";
const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;
const apiKey = process.env.REACT_APP_GOOGLE_MAP_API;

const AddModel = ({ onClose, title, open }) => {
  const navigate = useNavigate();

  //DropDowns Starts
  const [addressTypeOptions, setAddressTypeOptions] = useState([]);
  const [streetOptions, setStreetOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${baseURL}/get/masterData?type=addressType,unitType,streetType,state`
      )
      .then((response) => {
        const master = response.data;
        setAddressTypeOptions(master.data[0].values);
        setStateOptions(master.data[1].values);
        setStreetOptions(master.data[2].values);
        setUnitOptions(master.data[3].values);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  //DropDowns Ends

  //FormData Starts
  const [formdata, setFormdata] = useState({
    installer: {
      name: "",
      firstName: "",
      lastName: "",
      fullAddress: {
        addressType: "Physical",
        unitType: "",
        unitNumber: null,
        streetNumber: null,
        streetName: "",
        streetType: "",
        suburb: "",
        state: "",
        postCode: null,
      },
      emailId: "",
      mobileNo: "",

      selectYourRole: [],

      permission: [],

      stateOfServices: [],

      password: "Installer@123",
    },
    installerCECID: "",
    installerlicenceExpiry: "",
    installerCECLicensePhoto: "",
    electricianLicenseNo: "",
    electricianlicenceExpiry: "",
    electricianLicensePhoto: "",
    designerCECID: "",
    designerlicenceExpiry: "",
    designerCECLicensePhoto: "",
    gasfitterCECID: "",
    gasfitterLicensePhoto: "",
    gasfitterLicenseExpiry: "",
    plumberCECID: "",
    plumberCECLicensePhoto: "",
    plumberLicenseExpiry: "",
    profilePic: "",
  });
  //FormData Ends

  //manualButton
  const [manualButton, setMenualButton] = useState(false);
  const swithingAddress = () => {
    setMenualButton(!manualButton);
  };
  //manualButton

  // google auto address
  const [address, SetAddress] = useState("");
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const lat = result[0].geometry.location.lat();
    const lng = result[0].geometry.location.lng();
    setSelectedLocation({
      lat: lat,
      lng: lng,
    });
    const address = result[0].formatted_address;
    SetAddress(address);
    const addressComponents = result[0].address_components;
    const getComponent = (type) => {
      const component = addressComponents.find((component) =>
        component.types.includes(type)
      );

      if (component) {
        return component.short_name || "";
      } else {
        return "";
      }
    };
    const fullStreetName = getComponent("route");
    let streetName, streetType;
    if (fullStreetName.split(" ").length > 2) {
      const words = fullStreetName.split(" ");
      streetType = words.pop();
      streetName = words.join(" ");
    } else {
      [streetName, streetType] = fullStreetName.split(" ", 2);
    }
    const streetNo = getComponent("street_number");
    const suburb =
      getComponent("locality") ||
      getComponent("sublocality") ||
      getComponent("political");
    const state = getComponent("administrative_area_level_1");
    const postcode = getComponent("postal_code");
    setFormdata((prevState) => ({
      ...prevState,
      installer: {
        ...prevState.installer,
        fullAddress: {
          ...prevState.installer.fullAddress,
          streetNumber: streetNo,
          streetName: streetName,
          streetType: streetType,
          suburb: suburb,
          state: state,
          postCode: postcode,
        },
      },
    }));
    setMenualButton(!manualButton);
  };
  // google auto address
  //map

  const [selectedLocation, setSelectedLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  const mapRef = React.useRef();

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);
  //map

  //Functions Starts
  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormdata((prev) => ({
      ...prev,
      installer: {
        ...prev.installer,
        [name]: value,
      },
    }));
  };

  const handleInputMobileNum = (e) => {
    const { name, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      installer: {
        ...prev.installer,
        [name]: value,
      },
    }));
  };

  const [file, setFile] = useState([]);
  const [error, setError] = useState("");

  const handleALLImageInputs = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    const fileSize = file.size;

    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      let maxSizeInBytes;

      if (file.type === allowedTypes[0]) {
        maxSizeInBytes = 2 * 1024 * 1024 // 2MB
      } else if (file.type === allowedTypes[1]) {
        maxSizeInBytes = 2 *1024 * 1024; // 2MB
      } else if (file.type === allowedTypes[2]) {
        maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
      } else {
        alert("Invalid file type. Please upload a JPEG/PNG/PDF file only.");
        setError("Invalid file type. Please upload a JPEG/PNG/PDF file only.");
        return;
      }

      if (fileSize <= maxSizeInBytes) {
        const updateBothFields = (field, val) => {
          setFormdata((prev) => ({
            ...prev,
            [`plumberCEC${field}`]: val,
            [`gasfitter${field}`]: val,
          }));
        };

        setFormdata((prev) => ({
          ...prev,
          [name]: file,
        }));
        setError("");
        setFile((prevFiles) => [...prevFiles, file]);

        if (
          formdata.installer.selectYourRole.includes("Plumber") &&
          formdata.installer.selectYourRole.includes("Gasfitter")
        ) {
          if (name.startsWith("plumberCEC")) {
            const field = name.replace("plumberCEC", "");
            updateBothFields(field, file);
          } else if (name.startsWith("gasfitter")) {
            const field = name.replace("gasfitter", "");
            updateBothFields(field, file);
          }
        }
      } else {
        alert(
          `File too big. ${file.type
            .split("/")[1]
            .toUpperCase()} file size should not exceed ${
            maxSizeInBytes / (1024*1024)
          } MB`
        );
        setError(
          `File too big. ${file.type
            .split("/")[1]
            .toUpperCase()} file size should not exceed ${
            maxSizeInBytes / (1024 *1024)
          } MB`
        );
        setFormdata((prev) => ({
          ...prev,
          [name]: "",
        }));
        e.target.value = "";
      }
    }
  };

  const handleInputsInstallerAddress = (e) => {
    const { name, type, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      installer: {
        ...prev.installer,
        fullAddress: {
          ...prev.installer.fullAddress,
          [name]: type === "number" ? Number(value) : value,
        },
      },
    }));
  };

  const handleCheckboxChange = (role) => {
    setFormdata((prevInstaller) => {
      const { selectYourRole } = prevInstaller.installer;
      let updatedRoles = [];

      if (role === "All") {
        updatedRoles =
          selectYourRole.length === 5
            ? []
            : ["Installer", "Electrician", "Designer", "Plumber", "Gasfitter"];
      } else {
        updatedRoles = selectYourRole.includes(role)
          ? selectYourRole.filter((r) => r !== role)
          : [...selectYourRole, role];
      }

      return {
        ...prevInstaller,
        installer: {
          ...prevInstaller.installer,
          selectYourRole: updatedRoles,
        },
      };
    });
  };
  const handleALLInputs = (e) => {
    const { name, value, type } = e.target;

    // Function to update both plumber and gasfitter fields
    const updateBothFields = (field, val) => {
      setFormdata((prev) => ({
        ...prev,
        [`plumber${field}`]: val,
        [`gasfitter${field}`]: val,
      }));
    };

    if (type === "file") {
      const file = e.target.files[0];
      setFormdata((prev) => ({
        ...prev,
        [name]: file,
      }));

      if (
        formdata.installer.selectYourRole.includes("Plumber") &&
        formdata.installer.selectYourRole.includes("Gasfitter")
      ) {
        if (name.startsWith("plumber")) {
          const field = name.replace("plumber", "");
          updateBothFields(field, file);
        } else if (name.startsWith("gasfitter")) {
          const field = name.replace("gasfitter", "");
          updateBothFields(field, file);
        }
      }
    } else {
      setFormdata((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (
        formdata.installer.selectYourRole.includes("Plumber") &&
        formdata.installer.selectYourRole.includes("Gasfitter")
      ) {
        if (name.startsWith("plumber")) {
          const field = name.replace("plumber", "");
          updateBothFields(field, value);
        } else if (name.startsWith("gasfitter")) {
          const field = name.replace("gasfitter", "");
          updateBothFields(field, value);
        }
      }
    }
  };

  const handleServiceCheckboxChange = (event) => {
    const { checked, value } = event.target;
    setFormdata((prevFormData) => {
      const newServiceStates = checked
        ? [...prevFormData.installer.stateOfServices, value]
        : prevFormData.installer.stateOfServices.filter(
            (service) => service !== value
          );
      return {
        ...prevFormData,
        installer: {
          ...prevFormData.installer,
          stateOfServices: newServiceStates,
        },
      };
    });
  };

  const handlePermissionCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let updatedPermissions = Array.isArray(formdata.installer.permission)
      ? [...formdata.installer.permission]
      : [];
    if (checked) {
      updatedPermissions.push(value);
    } else {
      updatedPermissions = updatedPermissions.filter(
        (permission) => permission !== value
      );
    }
    setFormdata({
      ...formdata,
      installer: {
        ...formdata.installer,
        permission: updatedPermissions,
      },
    });
  };
  //Functions Ends

  //API Call Starts
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem("userToken");
      const formData = new FormData();
      Object.keys(formdata).forEach((key) => {
        if (
          key !== "profilePic" ||
          key !== "installerCECLicensePhoto" ||
          key !== "designerCECLicensePhoto" ||
          key !== "electricianLicensePhoto" ||
          key !== "plumberLicensePhoto" ||
          key !== "gasfitterLicensePhoto"
        ) {
          formData.append(key, JSON.stringify(formdata[key]));
        }
      });

      formData.append("profilePic", formdata.profilePic);
      formData.append(
        "installerCECLicensePhoto",
        formdata.installerCECLicensePhoto
      );
      formData.append(
        "designerCECLicensePhoto",
        formdata.designerCECLicensePhoto
      );
      formData.append(
        "electricianLicensePhoto",
        formdata.electricianLicensePhoto
      );
      formData.append(
        "plumberCECLicensePhoto",
        formdata.plumberCECLicensePhoto
      );
      formData.append("gasfitterLicensePhoto", formdata.gasfitterLicensePhoto);
      const response = await axios.post(`${baseURL}/installer/add`, formData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        alert("Team Member Added Successfully");
        window.location.reload();
        setTimeout(() => {
          navigate("/teams");
        }, 800);
        setLoading(false);
      } else {
        console.error("Login failed. Unexpected status code:", response.status);
        setLoading(false);
      }
    } catch (error) {
      alert(error?.response?.data?.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  //API Call Ends

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);
  const fileInputRef6 = useRef(null);
  const handleImageButtonClick = (field) => {
    if (field === "profilePic") {
      fileInputRef1.current.click();
    } else if (field === "installerCECLicensePhoto") {
      if (!formdata.installer.selectYourRole.includes("Installer")) {
        alert("Role not selected");
        return;
      }
      fileInputRef2.current.click();
    } else if (field === "electricianLicensePhoto") {
      if (!formdata.installer.selectYourRole.includes("Electrician")) {
        alert("Role not selected");
        return;
      }
      fileInputRef3.current.click();
    } else if (field === "plumberCECLicensePhoto") {
      if (!formdata.installer.selectYourRole.includes("Plumber")) {
        alert("Role not selected");
        return;
      }
      fileInputRef5.current.click();
    } else if (field === "gasfitterLicensePhoto") {
      if (!formdata.installer.selectYourRole.includes("Gasfitter")) {
        alert("Role not selected");
        return;
      }
      fileInputRef6.current.click();
    } else {
      if (!formdata.installer.selectYourRole.includes("Designer")) {
        alert("Role not selected");
        return;
      }
      fileInputRef4.current.click();
    }
  };
  const handleRemoveImage = (imageName, fileInputRef) => {
    setFormdata((prev) => ({
      ...prev,
      [imageName]: "",
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div className="add-model-backdrop">
      <div className="add-model-content">
        <div className="add-model-header">
          <h3>Add New</h3>
          <button className="add-model-close-btn" onClick={onClose}>
            ✕
          </button>
        </div>
        <div className="row">
          <span className="popupbottomadd-border"></span>
        </div>
        <form className="add-model-form" onSubmit={handleSubmit}>
          <div className="row">
            <div style={{ display: "flex", gap: "20px" }}>
              <input
                className="add-name"
                type="text"
                name="name"
                maxLength={60}
                placeholder="Full Name*"
                value={formdata.installer.name || ""}
                onChange={handleInput}
                required
              />
              <input
                className="add-email"
                type="email"
                name="emailId"
                // maxLength={30}
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                placeholder="Email Id*"
                value={formdata.installer.emailId || ""}
                onChange={handleInput}
                required
              />
              <input
                className="add-phone"
                type="text"
                name="mobileNo"
                value={formdata.installer.mobileNo || ""}
                autoComplete="off"
                placeholder="Phone*"
                onChange={handleInputMobileNum}
                onKeyDown={(e) => {
                  if (e.target.value.length > 14) {
                    window.alert("Mobile number must be of 14 digits.");
                    e.target.value = " ";
                  }
                }}
                required
              />
              <div className="photo-upload">
                <label className="label-text">Person Photo</label>
                <input
                  ref={fileInputRef1}
                  className="add-personphoto"
                  type="file"
                  accept="image/*, application/pdf"
                  id="Addinstaller-PersonPhoto"
                  placeholder="Person Photo"
                  name="profilePic"
                  autoComplete="off"
                  onChange={handleALLImageInputs}
                  style={{ marginLeft: "0px" }}
                />
                {!formdata?.profilePic ? (
                  <img
                    src={`${image}/Assets/Teamdashboard/upload-photo.svg`}
                    alt=""
                    className="photo-main"
                    onClick={() => {
                      handleImageButtonClick("profilePic");
                    }}
                  />
                ) : (
                  <img
                    src={`${image}/Assets/UploadFiles/subtract.svg`}
                    alt=""
                    className="photo-main"
                    onClick={() => {
                      handleRemoveImage("profilePic", fileInputRef1);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div style={{ display: "flex" }}>
              <div className="Signup-Adress-container">
                {manualButton ? (
                  <div>
                    <div className="main-address-block">
                      <div className="row">
                        <div className="address-main-boxes address-box">
                          <select
                            className="address-type"
                            name="addressType"
                            value={
                              formdata.installer.fullAddress.addressType || ""
                            }
                            onChange={handleInputsInstallerAddress}
                          >
                            <option value="" disabled>
                              Address Type
                            </option>
                            {addressTypeOptions.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="address-main-boxes">
                          <select
                            className="address-type"
                            name="unitType"
                            value={
                              formdata.installer.fullAddress.unitType || ""
                            }
                            onChange={handleInputsInstallerAddress}
                          >
                            <option value="" disabled>
                              Unit Type
                            </option>
                            {unitOptions.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="unit-main-boxes">
                          <input
                            className="unit-no"
                            placeholder="Unit Number"
                            name="unitNumber"
                            value={
                              formdata.installer.fullAddress.unitNumber || ""
                            }
                            onChange={handleInputsInstallerAddress}
                            onKeyDown={(e) => {
                              if (e.target.value.length > 8) {
                                window.alert(
                                  "Unit number must be less than or equal to 8 digits."
                                );
                                e.target.value = " ";
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="address-main-boxes address-box">
                          <input
                            className="address-type"
                            placeholder="Street Number*"
                            maxlength="6"
                            name="streetNumber"
                            value={
                              formdata.installer.fullAddress.streetNumber || ""
                            }
                            onChange={handleInputsInstallerAddress}
                            onKeyDown={(e) => {
                              if (e.target.value.length > 6) {
                                window.alert(
                                  "Street number must be less than or equal to 6 digits."
                                );
                                e.target.value = " ";
                              }
                            }}
                            required
                          />
                          <span className=""></span>
                        </div>
                        <div className="address-main-boxes">
                          <input
                            className="address-type"
                            type="text"
                            maxLength={50}
                            name="streetName"
                            placeholder="Street Name*"
                            value={
                              formdata.installer.fullAddress.streetName || ""
                            }
                            onChange={handleInputsInstallerAddress}
                            required
                          />
                        </div>
                        <div className="unit-main-boxes">
                          <select
                            className="street-type"
                            name="streetType"
                            value={
                              formdata.installer.fullAddress.streetType || ""
                            }
                            onChange={handleInputsInstallerAddress}
                            required
                          >
                            <option value="" disabled>
                              Street Type<span className="required">*</span>
                            </option>
                            {streetOptions.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="address-main-boxes address-box">
                          <input
                            className="address-type"
                            type="text"
                            // maxLength={10}
                            name="suburb"
                            placeholder="Suburb*"
                            value={formdata.installer.fullAddress.suburb || ""}
                            onChange={handleInputsInstallerAddress}
                            required
                          />
                        </div>
                        <div className="state-main-boxes">
                          <select
                            className="state-code"
                            value={formdata.installer.fullAddress.state || ""}
                            name="state"
                            onChange={handleInputsInstallerAddress}
                            required
                          >
                            <option value="" disabled>
                              State<span className="required">*</span>
                            </option>
                            {stateOptions.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="address-main-boxes">
                          <input
                            className="address-type"
                            type="number"
                            placeholder="Post Code*"
                            name="postCode"
                            value={
                              formdata.installer.fullAddress.postCode || ""
                            }
                            onChange={handleInputsInstallerAddress}
                            onKeyDown={(e) => {
                              if (e.target.value.length > 4) {
                                window.alert(
                                  "Post Code must be equal to 4 digits."
                                );
                                e.target.value = " ";
                              }
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="addmap-section">
                      {address ? (
                        <div style={{ position: "relative", right: "0" }}>
                          <GoogleMap
                            mapContainerStyle={{
                              width: "421px",
                              height: "196px",
                            }}
                            center={selectedLocation}
                            zoom={15}
                            onLoad={onMapLoad}
                            options={{
                              mapTypeControl: true,
                            }}
                          >
                            {selectedLocation && (
                              <MarkerF
                                position={{
                                  lat: selectedLocation.lat,
                                  lng: selectedLocation.lng,
                                }}
                              />
                            )}
                          </GoogleMap>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="Signup-autoAdress-container">
                    <div className="Signup-autoAdress-border">
                      <PlacesAutocomplete
                        value={address}
                        onChange={SetAddress}
                        onSelect={handleSelect}
                        searchOptions={{
                          componentRestrictions: { country: "au" },
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="address-input">
                            <input
                              className="Signup-autoAdress-input"
                              id="address"
                              {...getInputProps({
                                type: "text",
                                placeholder: "Address*",
                              })}
                              required
                            />
                            <div className="address-auto-complete">
                              {loading ? <div>Loading</div> : null}
                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#41b6e6"
                                    : "#fff",
                                  color: suggestion.active ? "#fff" : "#000",
                                };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      <div className="address-auto-complete"></div>
                    </div>
                    <div className="Signup-menualAdress-container">
                      <button
                        className="Signup-switchingAdress-btn"
                        onClick={swithingAddress}
                      >
                        Enter Manually
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <b>Select Role</b>
            <div className="popup-checkbox">
              <input
                type="checkbox"
                className="popup-checkbox"
                name="All"
                checked={formdata.installer.selectYourRole.length === 5}
                onChange={() => handleCheckboxChange("All")}
              />
              <span className="check-text">All</span>
              <input
                type="checkbox"
                className="popup-checkbox"
                name="Installer"
                checked={formdata.installer.selectYourRole.includes(
                  "Installer"
                )}
                onChange={() => handleCheckboxChange("Installer")}
              />
              <span className="check-text">Installer</span>
              <input
                type="checkbox"
                className="popup-checkbox"
                name="Electrician"
                checked={formdata.installer.selectYourRole.includes(
                  "Electrician"
                )}
                onChange={() => handleCheckboxChange("Electrician")}
              />
              <span className="check-text">Electrician</span>
              <input
                type="checkbox"
                className="popup-checkbox"
                name="Designer"
                checked={formdata.installer.selectYourRole.includes("Designer")}
                onChange={() => handleCheckboxChange("Designer")}
              />
              <span className="check-text">Designer</span>
              <input
                type="checkbox"
                className="popup-checkbox"
                name="Plumber"
                checked={formdata.installer.selectYourRole.includes("Plumber")}
                onChange={() => handleCheckboxChange("Plumber")}
              />
              <span className="check-text">Plumber</span>
              <input
                type="checkbox"
                className="popup-checkbox"
                name="Gasfitter"
                checked={formdata.installer.selectYourRole.includes(
                  "Gasfitter"
                )}
                onChange={() => handleCheckboxChange("Gasfitter")}
              />
              <span className="check-text">GasFitter</span>
            </div>
          </div>
          <div className="row">
            <div style={{ display: "flex" }}>
              <div
                className={`addmain-details ${
                  formdata.installer.selectYourRole.includes("Installer")
                    ? ""
                    : "disabled"
                }`}
              >
                <b>Installer</b>
                <div className="row">
                  <input
                    className="add-cce"
                    type="text"
                    name="installerCECID"
                    placeholder="CEC ID*"
                    maxLength={10}
                    value={formdata.installerCECID || ""}
                    onChange={handleALLInputs}
                    disabled={
                      !formdata.installer.selectYourRole.includes("Installer")
                    }
                    required={formdata.installer.selectYourRole.includes(
                      "Installer"
                    )}
                  />
                </div>
                <div className="row">
                  <input
                    className="adddetail-input"
                    type="date"
                    name="installerlicenceExpiry"
                    placeholder="Installation Date*"
                    value={formdata.installerlicenceExpiry || ""}
                    onChange={handleALLInputs}
                    required={formdata.installer.selectYourRole.includes(
                      "Installer"
                    )}
                    min={new Date().toISOString().split("T")[0]}
                  />
                </div>
                <div className="row">
                  <div className="photo-upload">
                    <label className="label-cce">Installer CEC ID</label>
                    <input
                      ref={fileInputRef2}
                      className="add-ccephoto"
                      type="file"
                      accept="image/*, application/pdf"
                      name="installerCECLicensePhoto"
                      onChange={handleALLImageInputs}
                      disabled={
                        !formdata.installer.selectYourRole.includes("Installer")
                      }
                      required={formdata.installer.selectYourRole.includes(
                        "Installer"
                      )}
                    />
                    {!formdata?.installerCECLicensePhoto ? (
                      <img
                        src={`${image}/Assets/Teamdashboard/upload-photo.svg`}
                        alt=""
                        className="addphoto-main"
                        onClick={(e) => {
                          handleImageButtonClick("installerCECLicensePhoto");
                        }}
                      />
                    ) : (
                      <img
                        src={`${image}/Assets/UploadFiles/subtract.svg`}
                        alt=""
                        className="addphoto-main"
                        onClick={() =>
                          handleRemoveImage(
                            "installerCECLicensePhoto",
                            fileInputRef2
                          )
                        }
                      />
                    )}
                  </div>
                  <span className="ccephoto-size">
                    (JPG Max 2MB, PNG Max 2MB)
                  </span>
                </div>
              </div>
              <div
                className={`addmain-details ${
                  formdata.installer.selectYourRole.includes("Installer")
                    ? ""
                    : "disabled"
                }`}
              >
                <b>Electrician</b>
                <div className="row">
                  <input
                    className="add-cce"
                    type="text"
                    name="electricianLicenseNo"
                    placeholder="Licence Number*"
                    maxLength={10}
                    value={formdata.electricianLicenseNo || ""}
                    onChange={handleALLInputs}
                    disabled={
                      !formdata.installer.selectYourRole.includes("Electrician")
                    }
                    required={formdata.installer.selectYourRole.includes(
                      "Electrician"
                    )}
                  />
                </div>
                <div className="row">
                  <input
                    className="adddetail-input"
                    type="date"
                    name="electricianlicenceExpiry"
                    value={formdata.electricianlicenceExpiry || ""}
                    onChange={handleALLInputs}
                    disabled={
                      !formdata.installer.selectYourRole.includes("Electrician")
                    }
                    required={formdata.installer.selectYourRole.includes(
                      "Electrician"
                    )}
                    min={new Date().toISOString().split("T")[0]}
                  />
                </div>
                <div className="row">
                  <div className="photo-upload">
                    <label className="label-eleid">Electrician ID</label>
                    <input
                      ref={fileInputRef3}
                      className="add-ccephoto"
                      type="file"
                      accept="image/*, application/pdf"
                      name="electricianLicensePhoto"
                      disabled={
                        !formdata.installer.selectYourRole.includes(
                          "Electrician"
                        )
                      }
                      onChange={handleALLImageInputs}
                      required={formdata.installer.selectYourRole.includes(
                        "Electrician"
                      )}
                    />
                    {!formdata?.electricianLicensePhoto ? (
                      <img
                        src={`${image}/Assets/Teamdashboard/upload-photo.svg`}
                        alt=""
                        className="addphoto-main"
                        onClick={() =>
                          handleImageButtonClick("electricianLicensePhoto")
                        }
                      />
                    ) : (
                      <img
                        src={`${image}/Assets/UploadFiles/subtract.svg`}
                        alt=""
                        className="addphoto-main"
                        onClick={() =>
                          handleRemoveImage(
                            "electricianLicensePhoto",
                            fileInputRef3
                          )
                        }
                      />
                    )}
                  </div>
                  <span className="ccephoto-size">
                    (JPG Max 2MB, PNG Max 2MB)
                  </span>
                </div>
              </div>
              <div
                className={`addmain-details ${
                  formdata.installer.selectYourRole.includes("Designer")
                    ? ""
                    : "disabled"
                }`}
              >
                <b>Designer</b>
                <div className="row">
                  <input
                    className="add-cce"
                    type="text"
                    name="designerCECID"
                    placeholder="CEC ID"
                    maxLength={10}
                    value={formdata.designerCECID || ""}
                    onChange={handleALLInputs}
                    disabled={
                      !formdata.installer.selectYourRole.includes("Designer")
                    }
                    // required={formdata.installer.selectYourRole.includes(
                    //   "Designer"
                    // )}
                  />
                </div>
                <div className="row">
                  <input
                    className="adddetail-input"
                    type="date"
                    name="designerlicenceExpiry"
                    value={formdata.designerlicenceExpiry || ""}
                    disabled={
                      !formdata.installer.selectYourRole.includes("Designer")
                    }
                    onChange={handleALLInputs}
                    // required={formdata.installer.selectYourRole.includes(
                    //   "Designer"
                    // )}
                    min={new Date().toISOString().split("T")[0]}
                  />
                </div>
                <div className="row">
                  <div className="photo-upload">
                    <label className="label-design">Designer CEC ID</label>
                    <input
                      ref={fileInputRef4}
                      className="add-ccephoto"
                      type="file"
                      accept="image/*, application/pdf"
                      name="designerCECLicensePhoto"
                      disabled={
                        !formdata.installer.selectYourRole.includes("Designer")
                      }
                      onChange={handleALLImageInputs}
                      // required={formdata.installer.selectYourRole.includes(
                      //   "Designer"
                      // )}
                    />
                    {!formdata?.designerCECLicensePhoto ? (
                      <img
                        src={`${image}/Assets/Teamdashboard/upload-photo.svg`}
                        alt=""
                        className="addphoto-main"
                        onClick={() =>
                          handleImageButtonClick("designerCECLicensePhoto")
                        }
                      />
                    ) : (
                      <img
                        src={`${image}/Assets/UploadFiles/subtract.svg`}
                        alt=""
                        className="addphoto-main"
                        onClick={() =>
                          handleRemoveImage(
                            "designerCECLicensePhoto",
                            fileInputRef4
                          )
                        }
                      />
                    )}
                  </div>
                  <span className="ccephoto-size">
                    (JPG Max 2MB, PNG Max 2MB)
                  </span>
                </div>
              </div>
              <div
                className={`addmain-details ${
                  formdata.installer.selectYourRole.includes("Plumber")
                    ? ""
                    : "disabled"
                }`}
              >
                <b>Plumber</b>
                <div className="row">
                  <input
                    className="add-cce"
                    type="text"
                    name="plumberCECID"
                    placeholder="Accreditation Number*"
                    maxLength={10}
                    value={formdata.plumberCECID || ""}
                    onChange={handleALLInputs}
                    disabled={
                      !formdata.installer.selectYourRole.includes("Plumber")
                    }
                    required={formdata.installer.selectYourRole.includes(
                      "Plumber"
                    )}
                  />
                </div>
                <div className="row">
                  <input
                    className="adddetail-input"
                    type="date"
                    name="plumberLicenseExpiry"
                    value={formdata.plumberLicenseExpiry || ""}
                    disabled={
                      !formdata.installer.selectYourRole.includes("Plumber")
                    }
                    onChange={handleALLInputs}
                    required={formdata.installer.selectYourRole.includes(
                      "Plumber"
                    )}
                    min={new Date().toISOString().split("T")[0]}
                  />
                </div>
                <div className="row">
                  <div className="photo-upload">
                    <label className="label-design">Plumber ID</label>
                    <input
                      ref={fileInputRef5}
                      className="add-ccephoto"
                      type="file"
                      accept="image/*, application/pdf"
                      name="plumberCECLicensePhoto"
                      disabled={
                        !formdata.installer.selectYourRole.includes("Plumber")
                      }
                      required={formdata.installer.selectYourRole.includes(
                        "Plumber"
                      )}
                      onChange={handleALLImageInputs}
                    />
                    {!formdata?.plumberCECLicensePhoto ? (
                      <img
                        src={`${image}/Assets/Teamdashboard/upload-photo.svg`}
                        alt=""
                        className="addphoto-main"
                        onClick={() =>
                          handleImageButtonClick("plumberCECLicensePhoto")
                        }
                      />
                    ) : (
                      <img
                        src={`${image}/Assets/UploadFiles/subtract.svg`}
                        alt=""
                        className="addphoto-main"
                        onClick={() =>
                          handleRemoveImage(
                            "plumberCECLicensePhoto",
                            fileInputRef5
                          )
                        }
                      />
                    )}
                  </div>
                  <span className="ccephoto-size">
                    (JPG Max 2MB, PNG Max 2MB)
                  </span>
                </div>
              </div>
              <div
                className={`addmain-details ${
                  formdata.installer.selectYourRole.includes("Gasfitter")
                    ? ""
                    : "disabled"
                }`}
              >
                <b>GasFitter</b>
                <div className="row">
                  <input
                    className="add-cce"
                    type="text"
                    name="gasfitterCECID"
                    placeholder="Accreditation Number*"
                    maxLength={10}
                    value={formdata.gasfitterCECID || ""}
                    onChange={handleALLInputs}
                    disabled={
                      !formdata.installer.selectYourRole.includes("Gasfitter")
                    }
                    // required={formdata.installer.selectYourRole.includes(
                    //   "Gasfitter"
                    // )}
                  />
                </div>
                <div className="row">
                  <input
                    className="adddetail-input"
                    type="date"
                    name="gasfitterLicenseExpiry"
                    value={formdata.gasfitterLicenseExpiry || ""}
                    disabled={
                      !formdata.installer.selectYourRole.includes("Gasfitter")
                    }
                    onChange={handleALLInputs}
                    // required={formdata.installer.selectYourRole.includes(
                    //   "Gasfitter"
                    // )}
                    min={new Date().toISOString().split("T")[0]}
                  />
                </div>
                <div className="row">
                  <div className="photo-upload">
                    <label className="label-design">GasFitter ID</label>
                    <input
                      ref={fileInputRef6}
                      className="add-ccephoto"
                      type="file"
                      accept="image/*, application/pdf"
                      name="gasfitterLicensePhoto"
                      disabled={
                        !formdata.installer.selectYourRole.includes("Gasfitter")
                      }
                      // required={formdata.installer.selectYourRole.includes(
                      //   "Gasfitter"
                      // )}
                      onChange={handleALLImageInputs}
                    />
                    {!formdata?.gasfitterLicensePhoto ? (
                      <img
                        src={`${image}/Assets/Teamdashboard/upload-photo.svg`}
                        alt=""
                        className="addphoto-main"
                        onClick={() =>
                          handleImageButtonClick("gasfitterLicensePhoto")
                        }
                      />
                    ) : (
                      <img
                        src={`${image}/Assets/UploadFiles/subtract.svg`}
                        alt=""
                        className="addphoto-main"
                        onClick={() =>
                          handleRemoveImage(
                            "gasfitterLicensePhoto",
                            fileInputRef6
                          )
                        }
                      />
                    )}
                  </div>
                  <span className="ccephoto-size">
                    (JPG Max 2MB, PNG Max 2MB)
                  </span>
                </div>
              </div>
            </div>
            <div className="addmain-details-serveDetail">
              <b>Want to Serve in</b>
              <div className="what-servesection">
                {stateOptions.map((state, index) => (
                  <div
                    style={{ display: "flow", flexDirection: "row" }}
                    key={index}
                  >
                    <input
                      type="checkbox"
                      className="popup-checkbox"
                      value={state}
                      onChange={handleServiceCheckboxChange}
                    />
                    <span className="check-text" style={{ cursor: "pointer" }}>
                      {state}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row" style={{ cursor: "pointer" }}>
            <b>Set Permission</b>
            <div className="popup-checkbox">
              <input
                type="checkbox"
                className="popup-checkbox"
                value="Add Form"
                onChange={handlePermissionCheckboxChange}
              />
              <span className="check-text">Add Form</span>
              <input
                type="checkbox"
                className="popup-checkbox"
                value="Change Form"
                onChange={handlePermissionCheckboxChange}
              />
              <span className="check-text">Change Form</span>
              <input
                type="checkbox"
                className="popup-checkbox"
                value="Remove Form"
                onChange={handlePermissionCheckboxChange}
              />
              <span className="check-text">Remove Form</span>
              <input
                type="checkbox"
                className="popup-checkbox"
                value="Submit Form"
                onChange={handlePermissionCheckboxChange}
              />
              <span className="check-text">Submit Form</span>
              <input
                type="checkbox"
                className="popup-checkbox"
                value="Sale Form"
                onChange={handlePermissionCheckboxChange}
              />
              <span className="check-text">Sale Form</span>
            </div>
            <div className="popup-checkbox">
              <input
                type="checkbox"
                className="popup-checkbox"
                value="Request Signature"
                onChange={handlePermissionCheckboxChange}
              />
              <span className="check-text">Request Signature</span>
              <input
                type="checkbox"
                className="popup-checkbox"
                value="Mass Upload"
                onChange={handlePermissionCheckboxChange}
              />
              <span className="check-text">Mass Upload</span>
              <input
                type="checkbox"
                className="popup-checkbox"
                value="Make User Role"
                onChange={handlePermissionCheckboxChange}
              />
              <span className="check-text">Make User Role</span>
              <input
                type="checkbox"
                className="popup-checkbox"
                value="View Report"
                onChange={handlePermissionCheckboxChange}
              />
              <span className="check-text">View Report</span>
              <input
                type="checkbox"
                className="popup-checkbox"
                value="View Invoice"
                onChange={handlePermissionCheckboxChange}
              />
              <span className="check-text">View Invoice</span>
            </div>
          </div>
          <div className="row">
            <span className="popupbottomadd-border"></span>
          </div>
          <div style={{ display: "flex" }}>
            <div className="add-model-actions">
              <button type="button" onClick={onClose} className="cancel-hover">
                Cancel
              </button>
              <button type="submit" className="submit-hover" disabled={loading}>
                {loading ? "Adding..." : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddModel;
